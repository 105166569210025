import _ from 'lodash'
import React, { ChangeEvent } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import { validators } from '../../utils'
import OrderStructure from './OrderStructure'
import { OperationType, ProductionOrderDetails, ProductWithValue } from '../../services/OrderManagementService'
import { OrderValueRecord, RefundCalculatorServiceInstance as refundCalculatorService } from '../../services/RefundCalculatorService'
import * as OrderTypes from '../../api/order'
import Checkbox from './Checkbox'

interface PercentageRefundFormState {
  products: ProductWithValue[]
  percentage?: number
  includeShippingCosts: boolean
}

interface Props {
  onChangeRefund(orderRefund: OrderValueRecord): void
  orderDetails: OrderTypes.OrderDetails
  remainingRefundRecord: OrderValueRecord,
  nonRefundableProducts?: string[]
}

export default class PercentageRefundForm extends React.Component<Props, PercentageRefundFormState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      products: [],
      percentage: undefined,
      includeShippingCosts: false,
    }

    this.handlePercentageChange = this.handlePercentageChange.bind(this)
    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.handleShippingCosts = this.handleShippingCosts.bind(this)
  }

  render() {
    const { percentage, includeShippingCosts } = this.state
    const percentageErrorMessage = validators.percentage(percentage)

    return (
      <>
        <FormGroup>
          <Label>Percentage %</Label>
          <Input
            type="number"
            min="1"
            max="99"
            invalid={percentage !== undefined && !!percentageErrorMessage}
            onChange={this.handlePercentageChange}
            id="refund-percentage-input"
          />
          <FormFeedback>{percentageErrorMessage}</FormFeedback>
        </FormGroup>
        <OrderStructure
          data={this.props.orderDetails}
          handleChange={this.handleProductsChange}
          operation={OperationType.RefundPercentage}
          disabledProducts={this.props.nonRefundableProducts}
        />
        <FormGroup>
          <Checkbox id="includeShippingCosts" checked={includeShippingCosts} onChange={this.handleShippingCosts} />
          <Label for="includeShippingCosts" className="ml-4">
            Include shipping costs
          </Label>
        </FormGroup>
      </>
    )
  }

  private handlePercentageChange(event: ChangeEvent<{ value: string }>) {
    this.setState(
      {
        percentage: Number(event.target.value),
      },
      () => this.calculateRefund()
    )
  }

  private handleProductsChange(products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>) {
    this.setState(
      {
        products: products,
      },
      () => this.calculateRefund()
    )
  }

  private handleShippingCosts(event: any) {
    this.setState(
      {
        includeShippingCosts: event.target.checked,
      },
      () => this.calculateRefund()
    )
  }

  private calculateRefund() {
    if (this.state.percentage === undefined) {
      return
    }
    let orderRefund = refundCalculatorService.calculatePercentageRefund(
      this.state.percentage,
      this.state.includeShippingCosts,
      this.state.products,
      this.props.remainingRefundRecord
    )
    this.props.onChangeRefund(orderRefund)
  }
}
