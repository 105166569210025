import _ from 'lodash'
import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import * as commentActions from '../actions/commentActions'
import * as global from '../actions/globalActions'
import * as orderDetailsActions from '../actions/orderDetailsActions'
import { DetailedRefundRecord, GlobalConfiguration, NoteRequest, OrderDetails, Product } from '../api'
import NavigationLinks from '../components/generic/NavigationLinks'
import OrderHeader from '../components/generic/OrderHeader'
import OrderPrices from '../components/lists/OrderPrices'
import { CommonProps, GlobalPanels, StaticPanelsCollection, TogglePanel } from '../models'
import { AppStore } from '../reducers/states'
import './RefundsView.scss'
import { OperationType } from '../services/OrderManagementService'
import * as refundsActions from '../actions/refundsActions'
import { OrderValueRecord, OrderValueRecordType, ProductValueRecord } from '../services/RefundCalculatorService'
import PlaygroundProductsForm from '../components/forms/PlaygroundProductsForm'
import { RefundCalculatorServiceInstance as refundCalculatorService } from '../services/RefundCalculatorService'
import close from '../assets/img/close-white.svg'
import OrderValueCard from '../components/generic/OrderValueCard'
import { Form, FormGroup, Label } from 'reactstrap'
import Confirmation from '../components/forms/Confirmation'
import Select from '../components/forms/Select'
import ProductAmountRefundForm from '../components/forms/ProductAmountRefundForm'
import PercentageRefundForm from '../components/forms/PercentageRefundForm'
import OrderRefundForm from '../components/forms/OrderRefundForm'
import CreditNotesTable from '../components/generic/CreditNotesTable'
import CollapsableContainer from '../components/generic/CollapsableContainer'

interface Props extends CommonProps {
  order?: OrderDetails
  configuration: GlobalConfiguration
  staticPanels: StaticPanelsCollection
  isNotesInEditMode: boolean
  notesFilter?: string
  showNotesCreatedNotification: boolean
}

interface State {
  reason?: string,
  calculationType: string,
  refundInProgressRecord?: OrderValueRecord,
  showConfirmation: boolean
  playgroundWasUsed: boolean
  order?: OrderDetails
}

export enum RefundCalculationType {
  Order = 'Entire or partial order',
  ShippingCosts = 'Shipping costs',
  Percentage = '% of product price',
  Amount = 'Amount per product'
}

const calculationOptions = _.values(RefundCalculationType).map((value) => ({ value }))

const refundReasons = [
  '100% satisfaction guarantee partial refund compensation',
  'Compensation wavy pages',
  'Customer applies for 100% satisfaction guarantee, full refund',
  'Customer did not use promotion code',
  'Double payment',
  'Extra Compensation (agreed with TM)',
  'Commercial-bulk order',
  'Gift cards not usable in a combination',
  'Order cancelled',
  'Order partially cancelled',
  'Pick up at plant (shipping costs)',
  'Photo book title is missing / not readable',
  'Received a better promotion code offer',
  'Reupload did not work',
  'VAT refund - B2B customer'
]

const reasonOptions = refundReasons.map((value) => ({ value }))

class RefundsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { order: this.props.order, playgroundWasUsed: false, calculationType: RefundCalculationType.Order, showConfirmation: false }
    this.validate = this.validate.bind(this)
    this.onSubmitOrderRefund = this.onSubmitOrderRefund.bind(this)
    this.onChangeOrderRefund = this.onChangeOrderRefund.bind(this)
    this.onChangeReason = this.onChangeReason.bind(this)
    this.sendNote = this.sendNote.bind(this)
    this.toggleNotes = this.toggleNotes.bind(this)
    this.toggleNotesMode = this.toggleNotesMode.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
  }

  render() {
    return this.state.order ? this.renderForm(this.state.order) : <div />
  }

  componentDidMount() {
    if (!this.state.order || this.state.order.id !== this.props.match.params.orderId) {
      this.props.dispatch(orderDetailsActions.requestOrderDetails(this.props.match.params.orderId))
    } else if (document.body.scrollTop !== 0) {
      this.props.dispatch(global.showSpinnerForMoment())
    }
  }

  private handlePlaygroundOrderChange = (updatedProducts: Product[]) => {
    this.setState(prevState => {
      if (!prevState.order) return null
      let subtotal = 0
      let shipping = 0
      let discount = 0
      updatedProducts.forEach(p => {
        const base = (p.basePricePerItem ?? 0) * (p.quantity ?? 0)
        const disc = p.totalDiscount ?? 0
        subtotal += base
        discount += disc
        shipping += p.shippingCosts ?? 0
      })
      const total = subtotal + shipping + discount

      const updatedOrder = {
        ...prevState.order,
        products: updatedProducts,
        price: {
          ...prevState.order.price,
          subTotal: subtotal.toFixed(2),
          shipping: shipping.toFixed(2),
          discount: discount.toFixed(2),
          total: total.toFixed(2)
        }
      }

      return {
        playgroundWasUsed: true,
        calculationType: RefundCalculationType.ShippingCosts,   //workaround to reload OrderStructure (so that it reflects playground change)
        order: updatedOrder
      }
    })
  }

  private renderForm(order: OrderDetails) {
    const notesPanel: TogglePanel = {
      ...this.props.staticPanels[GlobalPanels.orderNotes.accessKey],
      toggle: this.toggleNotes,
    }

    const baseValueRecord = refundCalculatorService.getBaseValueRecord(order)
    const discountRecord = refundCalculatorService.getDiscountValueRecord(order)
    var remainingRefundableRecord = refundCalculatorService.subtractOrderValueRecord(baseValueRecord, discountRecord)
    remainingRefundableRecord.recordType = 'Remainder'
    const historyRefundToOrderValues = [...this.state.order?.detailedRefundRecords ?? []].map(x => this.mapHistoryRefundToOrderValue(x))
    remainingRefundableRecord = historyRefundToOrderValues.reduce((acc, refund) => {
      return refundCalculatorService.subtractOrderValueRecord(acc, refund);
    }, remainingRefundableRecord);

    const nonRefundableProducts = remainingRefundableRecord.productValueRecords.filter(p => p.value === 0).map(p => p.productCode);

    //todo: ideally, OrderStructure should reflect "still refundable" products according to remainingRefundableRecord
    //so that user can't select product for which remaining value = 0
    const validationError = this.validate(remainingRefundableRecord)
    const totalRefund = _.sum(this.state.refundInProgressRecord?.productValueRecords?.map(x => x.value) ?? [0]) + (this.state.refundInProgressRecord?.shippingCostValue ?? 0)

    const { calculationType, refundInProgressRecord, showConfirmation } = this.state

    return (
      <div className="refunds">
        <NavigationLinks orderCode={order.id} source={order.source} configuration={this.props.configuration} />
        <OrderHeader
          order={order}
          prefix="Refund:"
          notesPanelDetails={{
            notesPanel: notesPanel,
            isNotesInEditMode: this.props.isNotesInEditMode,
            notesFilter: this.props.notesFilter,
            sendNote: this.sendNote,
            showNotesCreatedNotification: this.props.showNotesCreatedNotification,
            toggleNotes: this.toggleNotes,
            toggleNotesMode: this.toggleNotesMode,
          }}
        />
        <div className="content">
          <div className="refund-form">
            <Form>
              <Confirmation
                isOpen={showConfirmation}
                toggle={this.toggleConfirmation}
                onSubmit={this.onSubmitOrderRefund}
                submitText="Yes"
                cancelText="No">
                <OrderValueCard orderValue={refundInProgressRecord!} order={this.state.order} title='Confirm Refund' />
              </Confirmation>
              {/* //todo: this generates runtime warning in the Console about "render and state changes", look into it */}
              <CollapsableContainer title="PLAYGROUND" folded={true}>
                <PlaygroundProductsForm
                  orderDetails={order}
                  onPlaygroundChange={this.handlePlaygroundOrderChange}
                  playgroundWasUsed={this.state.playgroundWasUsed}
                />
              </CollapsableContainer>
              <FormGroup>
                <Label>Refund reason</Label>
                <Select
                  onChange={this.onChangeReason}
                  value={this.state.reason}
                  emptyText="-- select a refund reason --"
                  options={reasonOptions}
                  id="refund-reason-selector"
                />
              </FormGroup>
              <FormGroup>
                <Label>Refund calculation</Label>
                <Select
                  onChange={(event) => this.onChangeCalculationType(event.target.value, remainingRefundableRecord)}
                  value={calculationType}
                  emptyText="-- select a refund calculation --"
                  options={calculationOptions}
                  id="refund-calculation-selector"
                />
              </FormGroup>
              {calculationType === RefundCalculationType.Amount && (
                <ProductAmountRefundForm
                  orderDetails={order}
                  remainingRefundRecord={remainingRefundableRecord}
                  onChangeRefund={this.onChangeOrderRefund}
                  nonRefundableProducts={nonRefundableProducts} />
              )}
              {calculationType === RefundCalculationType.Percentage && (
                <PercentageRefundForm
                  orderDetails={order}
                  remainingRefundRecord={remainingRefundableRecord}
                  onChangeRefund={this.onChangeOrderRefund}
                  nonRefundableProducts={nonRefundableProducts} />
              )}
              {calculationType === RefundCalculationType.Order && (
                <OrderRefundForm
                  orderDetails={order}
                  remainingRefundRecord={remainingRefundableRecord}
                  onChangeRefund={this.onChangeOrderRefund}
                  nonRefundableProducts={nonRefundableProducts} />
              )}
              {calculationType === RefundCalculationType.ShippingCosts && <></>}
              {validationError && <div className='validation-error'>{validationError}</div>}
            </Form>
          </div>
          <div className='refund-data'>
            <CollapsableContainer title="SUMMARY" folded={true}>
              <CreditNotesTable
                remainingValueRecord={remainingRefundableRecord}
                historyRefunds={historyRefundToOrderValues}
                refundInProgress={this.state.refundInProgressRecord}
              />
            </CollapsableContainer>
            {this.state.refundInProgressRecord &&
              <OrderValueCard title="Refund in progress" orderValue={this.state.refundInProgressRecord} order={this.state.order}>
                <button onClick={this.toggleConfirmation} disabled={!this.state.reason || !!validationError || totalRefund === 0} id="refund-amount-submit-button">
                  Submit
                </button>
              </OrderValueCard>}
          </div>
          <div className='refund-data'>
            <CollapsableContainer title="PRICE DETAILS" folded={true}>
              <OrderPrices order={order} />
            </CollapsableContainer>
          </div>
        </div>
      </div>
    )
  }

  private mapHistoryRefundToOrderValue(refund: DetailedRefundRecord): OrderValueRecord {
    return {
      orderCode: refund.orderCode,
      currency: this.state.order?.price?.currency ?? '???',
      date: refund.refundDate,
      shippingCostValue: refund.shippingCostRefundAmount,
      reason: refund.reason,
      recordType: 'Refund',
      productValueRecords: !refund.productRefunds ? [] : refund.productRefunds.map(p => ({
        productCode: p.productCode,
        quantity: p.quantity,
        value: p.productRefundAmount
      }) as ProductValueRecord)
    }
  }

  private sendNote(request: NoteRequest) {
    this.props.dispatch(commentActions.sendComment(request))
  }

  private toggleNotesMode(isEditMode?: boolean) {
    this.props.dispatch(orderDetailsActions.toggleNotesPanelMode(isEditMode))
  }

  private toggleNotes() {
    this.props.dispatch(orderDetailsActions.togglePanel(GlobalPanels.orderNotes.accessKey))
  }

  private toggleConfirmation() {
    this.setState({ showConfirmation: !this.state.showConfirmation })
  }

  private onChangeCalculationType(calculationType: string, remainingAmountRecord: OrderValueRecord) {
    if (calculationType === RefundCalculationType.ShippingCosts) {
      const shippingCostRefund = refundCalculatorService.calculateShippingCostRefund(remainingAmountRecord)
      shippingCostRefund.reason = this.state.reason
      this.onChangeOrderRefund(shippingCostRefund)
    }

    // Reset values but keep structure
    const resetRecord = this.state.refundInProgressRecord && {
      ...this.state.refundInProgressRecord,
      productValueRecords: [],
      shippingCostValue: 0,
      reason: this.state.reason
    }

    this.setState({
      calculationType,
      refundInProgressRecord: resetRecord || undefined
    })
  }

  private onChangeReason(event: ChangeEvent<{ value: string }>) {
    const reason = event.target.value
    if (!this.state.refundInProgressRecord) {
      this.setState({ reason: reason })
      return
    }

    const updatedRefund = {
      ...this.state.refundInProgressRecord,
      reason: reason
    } as OrderValueRecord
    this.setState({ reason: reason, refundInProgressRecord: updatedRefund })
  }

  private onChangeOrderRefund(orderRefund: OrderValueRecord) {
    this.setState({ refundInProgressRecord: orderRefund })
  }

  private validate(remainingValueRecord: OrderValueRecord): string | undefined {
    let validationError
    //throw a validation error if any product tries to refund more than is remaining
    if (this.state.refundInProgressRecord) {
      const validationValues = refundCalculatorService.subtractOrderValueRecord(remainingValueRecord, this.state.refundInProgressRecord)
      const invalidProduct = validationValues.productValueRecords.find((x) => x.value < 0)
      validationError =
        (invalidProduct) ? `Refund for product ${invalidProduct.productCode} is higher than its remaining refundable value` :
          (validationValues.shippingCostValue < 0) ? "Shipping Cost refund is higher than refundable Shipping Cost" :
            undefined
    }
    return validationError
  }

  private onSubmitOrderRefund() {
    if (this.state.playgroundWasUsed) {
      const playgroundRefund = {
        ...this.state.refundInProgressRecord!,
        reason: 'Playground',
        date: new Date()
      }

      // Convert current Credit Note to DetailedRefundRecord API model
      const fakeCreditNoteHistory: DetailedRefundRecord = {
        orderCode: this.state.order!.id,
        reason: playgroundRefund.reason,
        refundDate: playgroundRefund.date,
        productRefunds: playgroundRefund.productValueRecords.map(p => ({
          productCode: p.productCode,
          quantity: p.quantity,
          productRefundAmount: p.value
        })),
        shippingCostRefundAmount: playgroundRefund.shippingCostValue
      }

      const prevRefunds = this.state.order?.detailedRefundRecords || [];
      alert("PLAYGROUND MODE: moving CreditNote directly to history, no backend calls are made")
      this.setState(prevState => ({
        order: {
          ...prevState.order!,
          detailedRefundRecords: [...prevRefunds, fakeCreditNoteHistory]
        }
      }))
      //reset form
      this.toggleConfirmation();
      this.onChangeCalculationType(RefundCalculationType.ShippingCosts, this.state.refundInProgressRecord!)
      return
    }

    const orderRefund = this.state.refundInProgressRecord

    let operationType = OperationType.RefundAmount;
    switch (this.state.calculationType) {
      case RefundCalculationType.Amount:
        operationType = OperationType.RefundAmount
        break
      case RefundCalculationType.Order:
        operationType = OperationType.RefundOrder
        break
      case RefundCalculationType.Percentage:
        operationType = OperationType.RefundPercentage
        break
      case RefundCalculationType.ShippingCosts:
        operationType = OperationType.RefundShippingCosts
        break
    }

    this.props.dispatch(
      refundsActions.submitDetailedRefund({
        orderCode: this.props.order!.id,
        reason: orderRefund?.reason!,
        refundType: OperationType[operationType],
        productRefunds: orderRefund?.productValueRecords.map((pvr) => {
          return {
            productCode: pvr.productCode,
            quantity: pvr.quantity,
            productRefundAmount: pvr.value
          }
        }) ?? [],
        shippingCostRefundAmount: orderRefund?.shippingCostValue ?? 0
      })
    )
  }
}

function mapStateToProps(state: AppStore) {
  return {
    staticPanels: state.orderDetailsView.staticPanels,
    isNotesInEditMode: state.orderDetailsView.isNotesInEditMode,
    notesFilter: state.orderDetailsView.notesFilter,
    showNotesCreatedNotification: state.orderDetailsView.showNotesCreatedNotification,
    //copy order from App state to props - in order to put it to local state
    order: state.orderDetails.data,
    configuration: state.global.configuration,
  }
}

export default connect(mapStateToProps)(RefundsView)
