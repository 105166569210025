import { ResetReason } from '.';

export enum InputTypes {
  Orderlines = 0,
  ProductNumbers = 1
}

export interface ProductResetSuccess {
  productNumber: string
  orderLineIds?: string[]
}

export interface ProductResetFailed {
  productNumber: string
  orderLineIds?: string[]
  error: string
}

export interface ProductNotFound {
  productNumber: string
  reason: string
}

export interface OrderLineNotFound {
  orderLineId: string
  reason: string
}