import React from 'react'
import { DataSource, GlobalConfiguration } from '../../api'
import ActionLink from '../../components/generic/ActionLink'

interface Props {
  orderCode: string
  source: DataSource
  configuration: GlobalConfiguration
}

export default function NavigationLinks({ orderCode, configuration, source }: Props) {
  return (
    <React.Fragment>
      <ActionLink
        text="< back"
        className="mr-4"
        onClick={() => {
          window.history.back()
        }}
      />
    </React.Fragment>
  )
}
