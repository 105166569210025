import _ from 'lodash'
import React from 'react'
import { Alert, Row } from 'reactstrap'
import { FulfillmentPredictionResults } from '../../api'
import { toDateTimeHour, toDateTimeNew } from '../../utils/dateTime'
import Grid, { GridColumn } from './/Grid'
import LazyContainer from './LazyContainer'

interface TimelinePredictionProps {
  loading: boolean
  error?: string
  note?: string
  data?: FulfillmentPredictionResults
}

interface PredictionSummary {
  actualStartDate?: string
  predictedStartDate?: string

  actualShipDate?: string
  predictedShipDate?: string
}

//todo: PCT-13548 FF disconnect, remove this component
export default class TimelinePrediction extends React.Component<TimelinePredictionProps> {
  renderBody() {
    if (this.props.error) {
      return (
        <Alert color="danger" className="w-100">
          {this.props.error}
        </Alert>
      )
    }

    const data = this.props.data
    if (!data || !data.predictions) {
      return (
        <Alert color="danger" className="w-100">
          Something went wrong, prediction is not available for this order
        </Alert>
      )
    }

    const prediction = {
      actualStartDate: _.minBy(data.predictions, (p) => p.actualProductionStartedDateTime)?.actualProductionStartedDateTime,
      predictedStartDate: _.minBy(data.predictions, (p) => p.predictedProductionStartedDateTime)
        ?.predictedProductionStartedDateTime,

      actualShipDate:
        data.predictions.every((p) => !!p.actualShippedDateTime) &&
        _.maxBy(data.predictions, (p) => p.actualShippedDateTime)?.actualShippedDateTime,
      predictedShipDate: _.maxBy(data.predictions, (p) => p.predictedShippedDateTime)?.predictedShippedDateTime,
    } as PredictionSummary

    const emptyField = '-'

    const columns: GridColumn<PredictionSummary>[] = [
      {
        title: 'Actual start date',
        value: (p) => toDateTimeNew(p.actualStartDate) || emptyField,
      },
      {
        title: 'Predicted start date',
        value: (p) => toDateTimeHour(p.predictedStartDate) || emptyField,
      },
      {
        title: 'Actual ship date',
        value: (p) => toDateTimeNew(p.actualShipDate) || emptyField,
      },
      {
        title: 'Predicted ship date',
        value: (p) => toDateTimeHour(p.predictedShipDate) || emptyField,
      },
    ]

    return <Grid columns={columns} data={[prediction]} disableCopyOnClick={true} />
  }

  render() {
    return (
      <LazyContainer isLoading={this.props.loading} loaderSize="8em">
        <Row className="mt-2">
          <h2 className="mb-2">
            Prediction <i>(experimental)</i>
          </h2>
          {this.props.note && (
            <Alert color="warning" className="w-100">
              {this.props.note}
            </Alert>
          )}
        </Row>
        <Row className="flex-column">{this.renderBody()}</Row>
      </LazyContainer>
    )
  }
}
