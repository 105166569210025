import React, { FormEvent } from "react";
import { Alert, Form, FormGroup, Input, Label } from "reactstrap";
import { ResetReason } from "../../api";
import * as OrderTypes from "../../api/order";
import {
  OperationType,
  ProductWithValue,
} from "../../services/OrderManagementService";
import Confirmation from "./Confirmation";
import OrderStructure from "./OrderStructure";
import Select from "./Select";
import { OrderDetailsServiceInstance as Service } from "../../services/OrderDetailsService";

interface OrderResetFormProps {
  onSubmit(x: OrderTypes.InternalOperationRequest): void;
  data: OrderTypes.OrderDetails;
  reasons: ResetReason[];
  operationName: string;
}

interface OrderResetFormState {
  appVersion: string;
  modal: boolean;
  error: string | null;
  reasonCode?: string;
  reasonPhrase?: string;
  comment: string;
  products: ProductWithValue[];
}

export default class OrderResetForm extends React.Component<
  OrderResetFormProps,
  OrderResetFormState
> {
  constructor(props: OrderResetFormProps) {
    super(props);

    this.state = {
      appVersion: process.env.REACT_APP_VERSION || "",
      modal: false,
      error: "",
      comment: "",
      products: [],
    };

    this.handleProductsChange = this.handleProductsChange.bind(this);
    this.handleGenericChange = this.handleGenericChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.submitState = this.submitState.bind(this);
  }

  handleProductsChange(products: Array<ProductWithValue>) {
    this.setState({
      error: null,
      products: products,
    });
  }

  handleGenericChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(({
      error: null,
      [name]: value,
    } as unknown) as OrderResetFormState);
  }

  handleReasonChange(event: any) {
    const { reasons } = this.props;

    const target = event.target;
    const code = target.value;
    const [match] = reasons.filter(
      (reason: ResetReason) => reason.errorID === code
    );

    this.setState({
      error: null,
      reasonCode: code,
      reasonPhrase: match.description,
    } as OrderResetFormState);
  }

  handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const { products, comment, reasonCode } = this.state;

    if (
      products.length === 0 ||
      products.every((product: ProductWithValue) => product.value === 0)
    ) {
      this.setState({ error: "Check entire order or some part" });
      return;
    }

    if (!comment) {
      this.setState({ error: "Please put short reason" });
      return;
    }

    if (comment.trim().length > 254) {
      this.setState({
        error: "Please input a shorter reason. (Maximum 254 characters)",
      });
      return;
    }

    if (!reasonCode) {
      this.setState({ error: "Please select a reason code" });
      return;
    }

    this.toggleModal();
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  submitState() {
    const {
      appVersion,
      comment,
      products,
      reasonCode,
      reasonPhrase,
    } = this.state;
    const {
      data: { id, vendor, channel, invoiceId, source },
    } = this.props;

    const payload: OrderTypes.InternalOperationRequest = {
      appVersion,
      operation: OrderTypes.InternalOperationType.Reset,
      code: id,
      vendor,
      channel,
      reasonCode,
      reasonPhrase,
      comment: comment.trim(),
      products,
      productionOrders: [],
      invoiceId,
      source,
    };
    this.props.onSubmit(payload);
  }

  renderConfirmationBody() {
    const { products } = this.state;
    const { operationName } = this.props;

    if (!products) {
      return;
    }

    if (
      products.every(
        (product: ProductWithValue) => product.value === product.quantity
      )
    ) {
      return (
        <div>
          Do you really want to <b>{operationName}</b> the entire order?
        </div>
      );
    }

    return (
      <div>
        <div>
          Do you really want to <b>{operationName}</b> the following part?
        </div>
        <div>
          <ul>
            {products
              .filter((product: ProductWithValue) => product.value > 0)
              .map((product: ProductWithValue) => (
                <li key={product.id}>
                  {product.id} :{" "}
                  {product.value === product.quantity
                    ? "entire product"
                    : `${product.value} / ${product.quantity} order lines`}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { modal, error, reasonCode, comment } = this.state;

    const { operationName, data: { orderEvents }, reasons } = this.props;

    const allDisabled = this.state.products.every(
      (product: ProductWithValue) => !!product.disabled
    );

    const reuploadRequested = this.props.data.products.some(
      (product) =>
        Service.resolveProductReuploadState(orderEvents, product.id) !==
        undefined
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Confirmation
          isOpen={modal}
          toggle={this.toggleModal}
          onSubmit={this.submitState}
          cancelText="Back"
        >
          {this.renderConfirmationBody()}
        </Confirmation>
        {error && <Alert color="danger">{error}</Alert>}
        {this.props.data.products.some(
          (product) =>
            !product.pdfUrl && !product.albxUrl && !product.imageArchiveUrl
        ) && (
            <Alert color="warning">
              Redo not possible: product file is not available.
            </Alert>
          )}
        {reuploadRequested && (
          <Alert color="warning">
            Redo is not available for products for which a Reupload has been
            requested.
          </Alert>
        )}
        <OrderStructure
          data={this.props.data}
          handleChange={this.handleProductsChange}
          hideProductionOrders={true}
          operation={OperationType.Reset}
        />
        <FormGroup>
          <Label className={allDisabled ? "disabled" : undefined}>
            {operationName} code
          </Label>
          <Select
            emptyText="-- select a code --"
            onChange={this.handleReasonChange}
            value={reasonCode}
            options={reasons.map((reason: ResetReason) => ({ value: reason.errorID, text: reason.description }))}
            disabled={allDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="OrderManagementView_ReasonPhrase" className={allDisabled ? 'disabled' : undefined}>
            {operationName} reason
          </Label>
          <Input
            type="textarea"
            id="OrderManagementView_ReasonPhrase"
            name="comment"
            value={comment}
            onChange={this.handleGenericChange}
            disabled={allDisabled}
          />
        </FormGroup>
        <button
          type="submit"
          disabled={!!error || allDisabled}
          style={{ marginBottom: "10px" }}
        >
          Submit
        </button>
      </Form >
    );
  }
}
