import React from 'react'
import { OrderDetails, Product } from '../../api'
import { FormGroup } from 'reactstrap'
import './PlaygroundProductsForm.scss'

type updatableKeys = 'pricePerItem' | 'quantity' | 'discount' | 'shipping'

class PlaygroundProduct {
  product: string
  pricePerItem: number
  quantity: number
  discount: number
  shipping: number
  total: number
  paid: number
  private originalProduct: Product

  constructor(originalProduct: Product) {
    this.originalProduct = originalProduct
    this.product = originalProduct.id
    this.pricePerItem = originalProduct.totalPricePerItem ?? 0
    this.quantity = originalProduct.quantity
    this.discount = (originalProduct.totalDiscount ?? 0) * -1
    this.shipping = originalProduct.shippingCosts ?? 0
    this.total = this.calculateTotal()
    this.paid = this.calculatePaid()
  }

  private calculateTotal(): number {
    return this.pricePerItem * this.quantity
  }

  private calculatePaid(): number {
    return this.calculateTotal() - this.discount
  }

  public updateValue(key: updatableKeys, value: number): void {
    if (key in this) {
      this[key] = value
      this.total = this.calculateTotal()
      this.paid = this.calculatePaid()
    }
  }

  static createRandom(): PlaygroundProduct {
    const randomPrice = Math.floor(Math.random() * 50) + 1
    const randomQuantity = Math.floor(Math.random() * 10) + 1
    const randomShipping = Math.floor(Math.random() * 20)
    const productCode = `RND${Math.floor(Math.random() * 1000)}`

    const randomProduct = {
      extras: [],
      lines: [],
      editor: { name: 'RND editor' },
      productId: productCode,
      id: productCode,
      title: productCode,
      status: 'Confirmed',
      quantity: randomQuantity,
      basePricePerItem: randomPrice,
      totalPricePerItem: randomPrice,
      totalPrice: randomPrice * randomQuantity,
      totalDiscount: 0,
      shippingCosts: randomShipping,
      bulkDiscount: 0
    } as Product

    return new PlaygroundProduct(randomProduct)
  }

  getUpdatedProduct(): Product {
    const totalPrice = this.pricePerItem * this.quantity
    return {
      ...this.originalProduct,
      quantity: this.quantity,
      basePricePerItem: this.pricePerItem,
      totalPricePerItem: this.pricePerItem,
      totalPrice: totalPrice,
      shippingCosts: this.shipping,
      bulkDiscount: this.discount ? -this.discount : 0,
      totalDiscount: this.discount ? -this.discount : 0
    }
  }
}

interface Props {
  onPlaygroundChange(products: Product[]): void
  orderDetails: OrderDetails
  playgroundWasUsed: boolean
}

const RefundPlaygroundOrderForm: React.FC<Props> = ({ orderDetails, onPlaygroundChange: onPlaygroundChange, playgroundWasUsed }) => {
  const mapPlaygroundTableDataFromProducts = (products: Product[]): PlaygroundProduct[] => {
    return products.map(p => new PlaygroundProduct(p))
  }

  const [playgroundTableData, setPlaygroundTableData] = React.useState<PlaygroundProduct[]>([])

  React.useEffect(() => {
    setPlaygroundTableData(mapPlaygroundTableDataFromProducts(orderDetails.products))
  }, [orderDetails.products])

  const convertPlaygroundToProducts = (rows: PlaygroundProduct[]): Product[] => {
    return rows.map(row => row.getUpdatedProduct())
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const parts = name.split('-')
    const productId = parts[0];
    const propertyName = parts[1] as updatableKeys

    setPlaygroundTableData((prevData) => {
      const updatedData = [...prevData]
      let numericValue = parseFloat(value)

      // Prevent 0 quantity
      if (propertyName === 'quantity' && (numericValue <= 0 || isNaN(numericValue))) {
        numericValue = 1
      }

      const rowIndex = updatedData.findIndex(row => row.product === productId)
      if (rowIndex === -1) {
        console.log(`Product id '${productId}' not found in playground table data`)
        return prevData
      }

      updatedData[rowIndex].updateValue(propertyName, numericValue)
      onPlaygroundChange(convertPlaygroundToProducts(updatedData))
      return updatedData
    })
  }

  const addRandomProduct = () => {
    const updatedData = [...playgroundTableData, PlaygroundProduct.createRandom()]
    setPlaygroundTableData(updatedData)
    onPlaygroundChange(convertPlaygroundToProducts(updatedData))
  }

  const calculatePlaygroundTableTotals = () => {
    const totalQuantity = playgroundTableData.reduce((acc, row) => acc + row.quantity, 0)
    const total = playgroundTableData.reduce((acc, row) => acc + row.total, 0)
    const totalDiscount = playgroundTableData.reduce((acc, row) => acc + row.discount, 0)
    const totalPaid = playgroundTableData.reduce((acc, row) => acc + row.paid, 0)
    const totalShipping = playgroundTableData.reduce((acc, row) => acc + row.shipping, 0)
    return { totalQuantity, total, totalDiscount, totalPaid, totalShipping }
  }

  const { totalQuantity, total, totalDiscount, totalPaid, totalShipping } = calculatePlaygroundTableTotals()

  return (
    <FormGroup className="summary">
      <table className="playground">
        <thead>
          <tr>
            <th>Product</th>
            <th>Item price</th>
            <th>Qty</th>
            <th>Total</th>
            <th>Discount</th>
            <th>Paid</th>
            <th>Shipping</th>
          </tr>
        </thead>
        <tbody>
          {playgroundTableData.map((row) => (
            <tr key={row.product}>
              <td>{row.product}</td>
              <td>
                <input
                  type="number"
                  style={{ width: '60px' }}
                  name={`${row.product}-pricePerItem`}
                  min="0"
                  value={row.pricePerItem}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <input
                  type="number"
                  style={{ width: '40px' }}
                  name={`${row.product}-quantity`}
                  min="0"
                  value={row.quantity}
                  onChange={handleInputChange}
                />
              </td>
              <td>{row.total.toFixed(2)}</td>
              <td>
                <input
                  type="number"
                  style={{ width: '70px' }}
                  name={`${row.product}-discount`}
                  min="0"
                  value={row.discount}
                  onChange={handleInputChange}
                />
              </td>
              <td>{row.paid.toFixed(2)}</td>
              <td>
                <input
                  type="number"
                  style={{ width: '60px' }}
                  name={`${row.product}-shipping`}
                  min="0"
                  value={row.shipping}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td />
            <td><strong>Total</strong></td>
            <td><strong>{totalQuantity}</strong></td>
            <td><strong>{total.toFixed(2)}</strong></td>
            <td><strong>{totalDiscount.toFixed(2)}</strong></td>
            <td><strong>{totalPaid.toFixed(2)}</strong></td>
            <td><strong>{totalShipping.toFixed(2)}</strong></td>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign: 'left' }}>
              <button
                type="button"
                onClick={addRandomProduct}
                style={{ margin: '2px 0', padding: '4px 50px', fontSize: '0.875rem' }}
              >
                Add Random Product
              </button>
            </td>
            <td colSpan={2}>
              <span className={`playground-badge ${playgroundWasUsed ? 'active' : 'inactive'}`}>
                PLAYGROUND: {playgroundWasUsed ? 'ACTIVE' : 'Inactive'}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </FormGroup>
  )
}

export default RefundPlaygroundOrderForm
